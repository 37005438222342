.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input {
  position: relative;
  display: block;
  outline: none;
  border: none;

  font-family: Jost;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  text-align: left;
  color: var(--black70);

  padding: 16px 24px;
  border-radius: 14px;
  background: var(--bckgGray);
  margin: 8px 0 4px;
}

.input::placeholder {
  color: var(--black20);
}

.label {
  padding: 0 24px;
  font-family: Jost;
  font-weight: 600;
  font-size: 20px;
  text-align: justify;
  color: var(--black);
  width: max-content;
}


.error {
  width: max-content;
  padding: 0 24px;
  font-family: Jost;
  display: block;
  min-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: var(--black20);
  visibility: visible;
}

.noError {
  visibility: hidden;
}

.inputError,
.labelError {
  color: var(--red);
  border-color: var(--red);
}

.tags {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 16px 0;
  padding: 0 24px;
}

.removeBtn{
  width: 10px;
  height: 10px;
  background: url('../../img/xBtn.svg') no-repeat center;
  background-size: 100%;
  margin-left: 12px;
}

.tagBtn {
  padding: 6px 12px;
  background: var(--white);
  border: 2px solid var(--yellow);
  border-radius: 10px;
  margin: 0 6px 6px 0;

  font-family: Jost;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
}