.main {
  width: 100%;
  max-width: 900px;
  padding: 40px 0;
  position: relative;
  margin: 0 auto;
}

.header {
  display: flex;
  margin-bottom: 70px;
}

.title {
  font-weight: bold;
  font-size: 34px;
  line-height: 100%;
  margin-top: 40px;
  word-break: break-all;
  cursor: pointer;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-transform: capitalize;
}

.title span {
  display: block;
  margin-top: 14px;
}

.photos {
  display: flex;
  position: relative;
}

.imgWrapPass,
.imgWrap {
  position: relative;
  width: max-content;
  margin-right: 10px;
  width: 180px;
  height: 240px;
  object-fit: cover;
  background: var(--bckgGray);
  border-radius: 2px;
  border: 1px solid var(--black05);
  position: relative;
  overflow: hidden;
}

.imgWrap:first-of-type {
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
}

.imgWrap:last-of-type {
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
}

.imgWrapPass:hover::before,
.imgWrap:hover::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../img/download.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-color: var(--black60);
  z-index: 100;
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imgWrapPass {
  border-radius: 16px;
  width: 140px;
  height: 140px;
  background: var(--white);
}

.imgWrapPass .photo {
  object-fit: contain;
}

.btns {
  display: grid;
  grid-auto-flow: column;
  column-gap: 12px;
  margin-top: 40px;
}

.goBack,
.requestBtn,
.actionBtn,
.removeBtn {
  font-weight: 600;
  font-size: 16px;
  height: 44px;
  padding: 0 30px;
  border: 2px solid var(--yellow);
  border-radius: 12px;
  color: var(--text);
}

.goBack {
  position: fixed;
  top: 40px;
  left: 190px;
  font-size: 0;
  border: none;
  background: url('../../img/arrowLeft.svg') no-repeat center center;
  background-color: var(--black03);
}

.requestBtn {
  border: none;
  background: var(--yellow);
  width: max-content;
}

.removeBtn {
  display: block;
  border-color: var(--red);
  color: var(--red);
  font-size: 16px;
  margin: 60px auto 0;
}

.blockWrap {
  width: 100%;
  margin: 40px 0;
}

.blockName {
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: var(--text);
  width: 100%;
  display: block;
  padding: 0 34px;
}

.gridBody,
.block {
  padding: 34px;
  border-radius: 20px;
  background: var(--black03);
  margin: 16px 0 34px;
}

.gridBody {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 34px;
}

.blockField {
  display: grid;
  grid-template-columns: 68px 1fr;
  gap: 20px;
  padding: 10px 0;
}

.gridTwoCol {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 34px;
}

.gridContacts {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 34px;
}

.link {
  font-family: Jost;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: var(--text);
  width: max-content;
  word-break: break-all;
  display: block;
  padding: 10px 0;
  cursor: pointer;
}

.gridTwoCol .blockField {
  grid-template-columns: 102px 1fr;
}

.blockFieldLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: var(--black30);
  text-align: right;
}

.blockFieldData {
  font-family: Jost;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: var(--text);
  word-break: break-all;
  width: max-content;
  cursor: pointer;
}

.title span:active,
.link:active,
.blockFieldData:active {
  color: var(--yellow) !important;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.tag {
  height: 34px;
  font-size: 16px;
  font-weight: 500;
  padding: 0 20px;
  border: 2px solid var(--yellow);
  border-radius: 12px;
  background: var(--white);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 10px 0;
  color: var(--text);
  width: max-content;
}

.noPassport {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  line-height: 130%;
  color: var(--black50);
}

.noPassport span {
  margin-bottom: 16px;
  text-align: center;
}
