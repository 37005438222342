.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.header {
  padding: 40px;
  padding-bottom: 20px;
  background: var(--white);
  display: flex;
  align-items: baseline;
}

.form {
  padding: 40px;
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  gap: 22px;
}

.wrapEditor {
  width: 100%;
  height: 450px;
  margin: 20px 0;
}

.containerEditor {
  width: 100%;
  word-break: break-all;
  border: 1px solid var(--lightGray);
  border-radius: 5px;
  padding: 10px;
  font-family: 'Jost';
  font-size: 18px;
  line-height: 18px;
}

.containerEditor span {
  margin: 0 !important;
}

.pageNameHeader {
  font-size: 32px;
}

.pageNameUser {
  font-size: 22px;
  font-weight: 600;
  font-family: 'Jost';
  text-transform: capitalize;
}

.arrow {
  margin: 0 10px;
  width: 20px;
  height: 20px;
  background: url('../../img/arrow.svg') no-repeat center;
  background-size: 30%;
}

.profile {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.profileHead {
  display: flex;
  padding: 40px;
}

.photo {
  width: 120px;
  height: 140px;
  object-fit: cover;
  background: var(--bckgGray);
  border-radius: 2px;
  margin-right: 20px;
}

.profileHeadText {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.options {
  display: flex;
  justify-content: flex-end;
}

.options button {
  font-family: Jost;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: var(--black);
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 8px 16px;
}

.profileHeadName {
  font-family: Jost;
  font-weight: 800;
  font-size: 26px;
  line-height: 130%;
  color: var(--black);
  text-transform: capitalize;
}

.profileHeadSpec {
  margin-top: 14px;
  font-family: Jost;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  color: var(--lightGray);
}

.block {
  width: 100%;
}

.block:last-child {
  margin-bottom: 40px;
}

.blockName {
  font-family: Montserrat;
  font-weight: 800;
  font-size: 16px;
  line-height: 130%;
  text-transform: uppercase;
  color: var(--black);
  width: 100%;
  display: block;
  background: var(--bckgGray);
  padding: 8px 40px;
}

.blockData {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: row;
  padding: 30px 40px;
  gap: 30px 20px;
}

.blockField {
  display: flex;
  flex-direction: column;
  min-height: 50px;
}

.blockFieldLabel {
  font-family: Jost;
  font-size: 14px;
  line-height: 130%;
  color: var(--darkGray);
  margin-bottom: 6px;
}

.blockFieldData {
  font-family: Jost;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  color: var(--black);
  text-transform: capitalize;
}

.select {
  width: 300px !important;
}

.btns {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.actionBtn,
.removeBtn {
  font-weight: 600;
  font-size: 16px;
  height: 44px;
  padding: 0 30px;
  background: var(--yellow);
  border-radius: 12px;
  color: var(--text);
}

.removeBtn {
  display: block;
  border: 2px solid var(--red);
  background: var(--white);
  color: var(--red);
  font-size: 16px;
  margin-right: 20px;
}