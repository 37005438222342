.main {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--white);
  position: relative;
}

.logo {
  width: 200px;
}

.login {
  font-weight: 600;
  font-size: 20px;
  height: 44px;
  padding: 0 60px;
  background: var(--yellow);
  border-radius: 12px;
  color: var(--text);
  width: max-content;
  margin-top: 100px;
}
