.container {
  width: 100%;
  min-width: 1024px;
  min-height: 100vh;
}

.content {
  margin-left: 150px;
  padding: 0 120px;
  width: calc(100% - 150px);
  background: var(--white);
  box-shadow: 0 0 70px rgba(0, 0, 0, 0.11);
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.toastClassName {
  padding: 16px !important;
  border-radius: 14px !important;
}