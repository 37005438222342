*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Jost';
}

html {
  min-width: 1024px;
}

body {
  margin: 0;
  font-family: 'Jost' -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
button {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}

a:active,
button:active {
  opacity: 0.6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --black: #000000;
  --white: #ffffff;
  --bckgGray: #f5f5f5;
  --lightGray: #dedede;
  --darkGray: #9b9b9b;

  --red: #ff593f;
  --redLight: #ff593f27;
  --blue: #3245fe;
  --yellow: #ffc900;
  --yellowLight: #fffcea;
  --green: #33ab79;
  --white: #ffffff;
  --black: #000000;
  --text: #2d2d2d;
  --black90: rgba(0, 0, 0, 0.9);
  --black80: rgba(0, 0, 0, 0.8);
  --black70: rgba(0, 0, 0, 0.7);
  --black60: rgba(0, 0, 0, 0.6);
  --black50: rgba(0, 0, 0, 0.5);
  --black40: rgba(0, 0, 0, 0.4);
  --black30: rgba(0, 0, 0, 0.3);
  --black20: rgba(0, 0, 0, 0.2);
  --black10: rgba(0, 0, 0, 0.1);
  --black05: rgba(0, 0, 0, 0.05);
  --black03: rgba(0, 0, 0, 0.03);
  --bckgGray: #f7f7f7;
}
