.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.header {
  position: sticky;
  top: 0;
  background-color: var(--white);
  border-bottom: 1px solid var(--black10);
  padding-top: 40px;
  z-index: 2;
}

.simpleHeader {
  position: sticky;
  top: 0;
  padding: 40px;
  padding-bottom: 20px;
}

.line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;
}

.toolbar {
  display: grid;
  grid-auto-flow: column;
  column-gap: 20px;
}

.title {
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
}

.tableBtns {
  height: 34px;
  display: grid;
  grid-template-columns: 34px 50px 34px;
  column-gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: var(--black03);
  border-radius: 12px;
  padding: 0 10px;
}

.tableBtns span {
  text-align: center;
}

.pageName {
  font-family: Jost;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 100%;
  color: var(--black);
}

.table {
  width: 100%;
}

.headerRow,
.row {
  width: 100%;
  display: grid;
  grid-template-columns: 40px 1fr 200px 140px;
  padding: 12px 24px;
  background-color: var(--white);
  font-family: Jost;
  font-size: 16px;
  line-height: 130%;
  color: var(--text);
  height: max-content;
  text-decoration: none;
  border-radius: 14px;
  transition: 0.3s;
}

.headerRow {
  font-family: Jost;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: var(--black);
  height: 40px;
}


.row:nth-child(even) {
  background-color: var(--black03);
}

.row:hover {
  background-color: var(--yellowLight);
  transition: 0.3s;
}

.index {
  opacity: 0.2;
  font-size: 12px;
}

.nextPage,
.prevPage {
  width: 34px;
  height: 34px;
  background: url('../../img/arrow.svg') no-repeat center;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
}

.prevPage {
  transform: rotate(180deg);
}

.prevPage:hover {
  transform: scale(1.1) rotate(180deg);
  transition: 0.3s;
}

.nextPage:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.nextPage:active,
.prevPage:active {
  opacity: 0.2;
  transition: 0s;
}

.arrow {
  width: 20px;
  height: 20px;
  opacity: 0.3;
  margin-left: auto;
  background: url('../../img/arrow.svg') no-repeat center;
}

.search {
  width: 41px;
  height: 34px;
  background: url('../../img/search.svg') no-repeat center center;
  background-color: var(--black03);
  border-radius: 12px;
}

.create {
  font-weight: 600;
  font-size: 14px;
  height: 34px;

  padding: 8px 20px;
  background: var(--yellow);
  border-radius: 12px;
  color: var(--text);
}

.form {
  padding: 40px;
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  gap: 22px;
}

.remove {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--red);
  background: none;
}

.capitalize {
  text-transform: capitalize;
}

.actionBtn {
  font-weight: 600;
  font-size: 16px;
  height: 44px;
  padding: 0 30px;
  background: var(--yellow);
  border-radius: 12px;
  color: var(--text);
  width: max-content;
  margin: 0 auto;
}