.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.header {
  padding: 40px 0 0;
  position: sticky;
  top: 0;
  z-index: 6;
  background-color: var(--white);
}

.pageName {
  font-size: 32px;
}

.title {
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
}

.settings {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 40px 0;
  padding: 0 40px;
  gap: 16px;
}

.item {
  width: 100%;
  height: 200px;
  background: var(--bckgGray);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.btn {
  background: var(--yellow);
  color: var(--black);
}

.value {
  margin-top: 10px;
  font-size: 40px;
}

.labelDown {
  font-weight: 700;
  font-size: 14px;
  color: var(--black30);
}

.red {
  color: var(--red);
  background: var(--redLight);
}

.red .labelDown {
  color: var(--red);
}