.container {
  width: 150px;
  height: 100vh;
  padding: 40px 0;
  display: grid;
  grid-template-rows: 80px 1fr 60px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.logo {
  margin: 0 auto 50px;
}

.logo img {
  width: 70px;
}

.links {
  display: flex;
  flex-direction: column;
}

.logout,
.link {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;

  color: var(--text);
  padding: 21px 0;
  text-decoration: none;
  border: none;
  background: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.85;
}

.icon {
  width: 36px;
  height: 36px;
  margin-bottom: 2px;
}

.linkActive {
  color: var(--yellow);
  opacity: 1;
}