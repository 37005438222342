.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
}

.container span {
  margin-bottom: 4px;
  font-size: 13px;
  color: var(--black20);
  font-weight: 500;
}

.loader {
  display: flex;
  width: 150px;
  height: 2px;
  overflow: hidden;
}

.loader div {
  width: 100%;
  height: 100%;
  background: var(--black);
  animation: ani 2s infinite ease-out;
}

@keyframes ani {
  0% {
    transform: translateX(-100%);
    background: var(--black);
  }
  44% {
    transform: translateX(100%);
    background: var(--black);
  }
  45% {
    transform: translateX(100%);
    background: var(--yellow);
  }
  100% {
    transform: translateX(-100%);
    background: var(--yellow);
  }
}