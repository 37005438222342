.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.selectBtn,
.textarea,
.input {
  position: relative;
  display: block;
  outline: none;
  border: none;

  font-family: Jost;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  text-align: left;
  color: var(--black70);

  padding: 16px 24px;
  border-radius: 14px;
  background: var(--bckgGray);
  margin: 8px 0 4px;
}

.textarea::placeholder,
.input::placeholder {
  color: var(--black20);
}

.textarea {
  min-height: 140px;
}

.label {
  padding: 0 24px;
  font-family: Jost;
  font-weight: 600;
  font-size: 20px;
  text-align: justify;
  color: var(--black);
  width: max-content;
}


.error {
  width: max-content;
  padding: 0 24px;
  font-family: Jost;
  display: block;
  min-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: var(--red);
  visibility: visible;
}

.noError {
  visibility: hidden;
}

.inputError,
.labelError {
  color: var(--red);
  border-color: var(--red);
}

/* select */

.list {
  list-style: none;
  overflow-y: scroll;
  max-width: 300px;
  max-height: 200px;
  visibility: visible;
  outline: none;
  border-radius: 14px;
  background: var(--bckgGray);
}

.listHide {
  visibility: hidden;
}

.listItem {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: var(--black);
  padding: 16px 24px;
}

.selectBtn {
  padding: 16px 34px 16px 24px;
}

.selectBtn::after {
  content: '';
  display: block;
  width: 34px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: url('../../img/arrow.svg') no-repeat center center;
  transform: rotate(90deg);
  transition: 0.3s;
}

.selectBtnOpen::after {
  transform: rotate(-90deg);
  transition: 0.3s;
}